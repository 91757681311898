<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Brand</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Brand
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Brand</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_brand')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'addNewBrand', mode: 'create' })
          "
        >
          New Brand
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-9 d-block mr-0 pr-0">
          <select v-model="params.size" @change="searchFilter" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div class="col-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="searchFilter"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-50p">Brand Name</th>
              <th class="wd-15">Status</th>
              <th class="wd-30p text-center table-end-item" v-if="checkIfAnyPermission(['edit_brand','delete_brand'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && brandLists.length > 0">
            <tr v-for="(brand, index) in brandLists" :key="index">
              <th scope="row" class="table-start-item">{{ pageSerialNo+index }}.</th>
              <td>{{ brand.name }}</td>
              <td><span v-if="brand.status == 1">Active</span><span v-else>In-Active</span></td>
              <td class="text-center table-end-item" v-if="checkIfAnyPermission(['edit_brand','delete_brand'])">
                <a
                  href="javascript:;"
                  v-if="checkSinglePermission('edit_brand')"
                  class="mr-2"
                  title="Edit"
                  @click="edit('editBrand', brand.id)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" title="Delete" v-if="checkSinglePermission('delete_brand')" @click="drop(brand.id)">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
           <tbody v-else-if="error">
            <tr>
              <td colspan="8" class="text-center">{{error_message}}</td>
            </tr>
          </tbody>
           <tbody v-else-if="!loading && brandLists.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No records found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
         
        </table>
         <Paginate :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
          <Create @parent-event="getData()" />
          <Edit @parent-event="getData()" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Create from "./create";
import Edit from "./edit";
import Paginate from 'vuejs-paginate';
import Services from "./Services/Services";
import _ from 'lodash';
export default {
  components: {
    Paginate,Create,Edit
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "pagination",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("brands",['brandLists']),
  },
  data(){
    return{
      loading:false,
      error:false,
      error_message:'',
      params:{
        size:10,
        searched:'',
        offset:0,
      },
      totalPageCount:0,
      pageSerialNo:1,
    }
  },
  methods: {
     clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    getData(){
       this.loading = true;
      //this.$store.commit("getData", `api/unit/types/size/${this.filter.size}`);
       Services.getFilteredBrandLists(this.params).then(res=>{
        this.$store.commit("brands/setBrandLists",res.data.data.data);
        this.totalPageCount = res.data.data.total_no_of_pages;
        this.loading = false;
      }).catch(err=>{
         if (err.response.data.error_message == "Access Denied.") {
          this.error_message = "Access Denied !!!";
        } else {
          this.error_message = "Error Fetching data from the server.";
        }
        this.loading = false;
        this.error = true; 
        console.log(err);
      });
    },
    searchFilter: _.debounce(function(){
        this.loading = true;
        Services.getFilteredBrandLists(this.params).then(res=>{
            this.$store.commit("brands/setBrandLists",res.data.data.data);
            this.totalPageCount = res.data.data.total_no_of_pages;
            this.loading = false;
        }).catch(err=>{
           if (err.response.data.error_message == "Access Denied.") {
          this.error_message = "Access Denied !!!";
        } else {
          this.error_message = "Error Fetching data from the server.";
        }
            this.loading = true;
            this.error =true;
            console.log(err);
        });
    },1000),
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$swal({
       title: 'Do you really want to delete Brand ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm:() => {
          // delete lead
           return  Services.deleteBrand(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
             this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  mounted() {
    this.getData();
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      id="modal-multi-newItem"
      v-if="modalId == 'editBrand'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Edit Brand
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose', 'addNewBrand')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
                       <div class="modal-body row">
              <div class="col-md-12">
                <div class="group">
                  <label class="group-label">Basic Information</label>
                  <div class="group-attribute-container">
                    <div class="row">
                      <div class="col-md-5 mt-0 mb-1">
                        <input
                          type="file"
                          id="Item_image"
                          style="display: none"
                          @change="base64Image"
                        />
                        <label for="Item_image" class="popup-img-container">
                          <span
                            >Browse <br />
                            Image</span
                          >
                          <div class="popup_img">
                            <img
                              class="popup_img"
                              :src="
                                    formData.image
                                      ? formData.image
                                      : '/assets/img/logosmall.png'
                                  "
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Brand Name *</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <input
                          type="text"
                          ref="title"
                          class="form-control"
                          placeholder="Brand Name"
                          v-model="formData.name"
                              required
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 mr-0 pr-0">Status *</div>
                      <div class="col-md-7 input-group ml-0 pl-0">
                        <select class="form-control" v-model="formData.status" required>
                          <option value="1">Active</option>
                          <option value="0">In-Active</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{ disableSubmitButton ? "Submitting ...." : "Submit"}}
                </button>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {

  data() {
    return {
      loading:false,
      disableSubmitButton:false,
      formData: {
        name:'',
        status:1,
        image:null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
      "authData"
    ]),
    ...mapGetters("brands",['brandLists']),
  },
  methods: {
    base64Image(e) {
      this.formData.image = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.image = e.target.result;
      };
    },
    submit() {
      this.disableSubmitButton = true;
        Services.editBrand(this.formData,this.formData.id).then(res=>{
            this.disableSubmitButton = false;
              this.setNotification(res.data.success_message);
              this.$store.dispatch("modalClose");
              this.$emit('parent-event');
              this.loading = false;
        }).catch(err=>{
            this.disableSubmitButton = false;
            this.error=false;
            this.loading = false;
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
        });
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  watch:{
    modalId(value){
        if(value == 'editBrand'){
          this.$nextTick(() => this.$refs.title.focus());
            let id = this.dataId;
            let details = this.brandLists.find(function (data){
                return data.id == id;
            });
            this.formData = {...details};
        }
    }
  }
};
</script>

import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";
class BrandService{
    editBrand(formData,id){
        const url = `api/brand/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    getFilteredBrandLists(param){
        const url = `api/brand/filter`
        const params = {
            'page-index':param.size,
            'offset':param.offset,
            'searched':param.searched,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    createNewBrand(formData){
        const url = `api/brand/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    deleteBrand(id){
        const url = `api/brand/delete/${id}`;
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
}
export default new BrandService();